.DescriptionBox{
    padding: 70px;

}
.discrbutn{
    display: flex;
}
.discrbutn > button{
    width: 100px;
    background: none;
    border: 1px solid rgba(128, 128, 128, 0.802);
}