.logos_main {
    /* margin-top: 72px; */
    background: white;
    position: relative;
    z-index: 10;
}
.logo_sec {
    display: flex;
    WIDTH: 54%;
    gap: 5px;
    margin: auto;
}
.honda {
    width: 27%;
    /* height: 176px; */
    margin-top: 32px;
}

.hashimoto {
    width: 36%;
    /* height: 227px; */
}
.wareld {
    padding: 55px 0px;
    padding-left: 89px;
    width: 36%;
    object-fit: contain;
}
@media(max-width: 786px){
    
    .logo_sec {
        display: flex;
        WIDTH: 100%;
        gap: 5px;
        margin: auto;
    }
    .hashimoto {
        width: 36%;
        /* height: 227px; */
    }
    .honda {
        width: 25%;
        /* height: 176px; */
        margin-top: 2px;
        /* height: 110px; */
    }
    .wareld {
        /* padding: 55px 0px; */
        padding-left: 22px;
        width: 32%;
        object-fit: contain;
    }
}