.Cartitem{
    margin-top: 158px;
}
.titles{
    display: flex;
    gap: 7%;
    margin-left: 10%;
}

.value{


}
.valuemap{
    display: flex;
    gap: 12%;
    align-items: self-end;
    margin-left: 12%;
    border-top: 1px solid #d5d3d3;
    padding: 11px 0px
}
.valuemap > img{
    width: 60px;
}
.carttotal{
    margin-top: 30px;
}
.proceed{
    margin-top: 20px;
    width: 300px;
    height: 50px;
    background: rgb(63, 63, 131);
}
.error_p{
	font-size: 12px;
    color: red;
    text-align: end;
    margin-bottom: 0px;
}
/* template */


/*  */
.shopping-cart{
    margin-top: 68px !important;
    margin-bottom: 50px;
}

.in_cart {
    width: 26%;
    text-align: center;
    /* padding: 8px; */
    margin: auto;
    margin-bottom: 17px;
    margin-top: 6px;
    background: transparent;
    border: none;
    font-size: 17px;
    font-weight: 500;
}
.in_cart:focus{
	width: 8%;
    padding: 8px;
    margin: auto;
    margin-bottom: 17px;
    margin-top: 6px;
    background: transparent;
    border: none;
}
.cart__close{
    width: 16%;
}
.continue__btn {
    text-align: start;
    width: 50%;
}

.continue__btn.update__btn {
    text-align: right;
    width: 50%;
}


.continue__btn_a  {
    color: #111111;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 1px solid #e1e1e1;
    /* padding: 14px 0px; */
    display: inline-block;
    width: 230px;
	height: 47px;
    transition: all 0.3s;


}
.continue__btn_a:hover{
    border: 3px solid black;
}

.continue__btn_proceed{
	color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 1px solid #e1e1e1;
	height: 47px;
    display: inline-block;
	background-color: black;
    width: 200px;
    transition: all 0.3s;

}
.continue__btn_proceed:hover{
    background: white;
    border: 3px solid black;
    color: black;
}

.cart__discount {
	margin-bottom: 60px;
}

.cart__discount h6 {
	color: #111111;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 35px;
}

.cart__discount form {
	position: relative;
}

.cart__discount form input {
	font-size: 14px;
	color: #b7b7b7;
	height: 50px;
	width: 100%;
	border: 1px solid #e1e1e1;
	padding-left: 20px;
}

.cart_quantity{
    padding-top: 43px !important;
}
.cart_tot{
    padding-top: 53px !important;

}
.cart_rem{
    padding-top: 43px !important;

}
.cart__discount form input::-webkit-input-placeholder {
	color: #b7b7b7;
}

.cart__discount form input::-moz-placeholder {
	color: #b7b7b7;
}

.cart__discount form input:-ms-input-placeholder {
	color: #b7b7b7;
}

.cart__discount form input::-ms-input-placeholder {
	color: #b7b7b7;
}

.cart__discount form input::placeholder {
	color: #b7b7b7;
}

.cart__discount form button {
	font-size: 14px;
	color: #ffffff;
	font-weight: 700;
	letter-spacing: 2px;
	text-transform: uppercase;
	background: #111111;
	padding: 0 30px;
	border: none;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
}

/* .cart__total {
	background: #f3f2ee;
	padding: 35px 40px 40px;
	display: flex;
	flex-direction: column;
} */
.cart__total_form {
	background: #f3f2ee;
	padding: 35px 40px 40px;
	display: flex;
	flex-direction: column;
}

.cart__total_form h6 {
	color: #111111;
	text-transform: uppercase;
	margin-bottom: 25px;
}
.cart__total_form > label{
	width: 100%;
	text-align: start;
	margin-bottom: 0px;
	font-size: 15px;
}
.cart__total_form > input{
    width: 100%;
    margin-bottom: 2px;
    margin-top: 16px;
    height: 48px;
    padding: 0px 11px;
    border: 1px solid #e1e1e1;
    font-size: 14px;
}
.cart__total_form > textarea{
	width: 100%;
    margin-bottom: 16px;
    margin-top: 7px;
    height: 100px;
    padding: 11px 11px;
    border: 1px solid #e1e1e1;
    font-size: 14px;
}



.primary-btn {
    display: block;
	height: 47px;
    text-align: center;
    letter-spacing: 1px;
    width: 100%;
    background: black;
    color: white;
	font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: all 0.3s;
    border: none;
}
.primary-btn:hover{
    background: white;
    border: 3px solid black;
    color: black;
}
.cartimg{
    width: 100px;
    height: 100px;
    object-fit: contain;
}   
.cart_table_title_tr{
    display: flex;
    text-align: start;
}
.cart_table_title1{
    width: 45%;
}
.cart_table_title2{
    width: 16%;
    text-align: center;
    

}
.cart_table_title3{
    width: 29%;
    text-align: center;

}
.cart_table_title4{
    width: 5%;
}
.removeicon{
    background-color: #f3f2ee;
    width: 40px;
    height: 40px;
    border-radius: 24px;
    padding: 6px;
}
.removeicon:hover {
    background-color: #e4e3e2
}
/* breadcrums */
.breadcrumb-option{
    background-color: #f3f2ee;;
    height: 200px;
}
.bread{
    text-align: start;
    width: 85%;
    margin: auto;
    margin-top: 69px;

}
.breadcrumb__text{
    align-items: center;
    justify-content: center;
}

/* n?ew */
.cart_main_head{
	height: 50px !important;
	vertical-align: middle;
}
.tbodycart {
    vertical-align: middle !important;
}
.cart_col1{
	display: flex;
    gap: 8px;    
	min-width: 300px;
	padding: 16px 0px !important;
	word-break: break-all;
	max-width: 100%;

}
.product__cart__item__text {
    width: 70%;
    padding-top: 29px;
    text-align: start;
    padding-left: 7px;
	padding: 11px 0px;
}

.product__cart__item__text>h6{
	font-size: 16px;
}
.product__cart__item__text>h5{
	font-size: 13px;
}
.cart_quantity{
	width: 139px;
    text-align: start;
}
.quant {
    /* margin-top: 38px; */
    display: flex;
    gap: 20px;
    font-size: 17px;
    font-weight: 600;
}
.cart_prduct{
	text-align: start;
}
.cart_tot{
	/* padding-top: 49px !important; */
}
.cart_rem{
	/* padding-top: 40px !important; */
}
.mob_cart_main{
	display: none;
}
/* .fill_view{
	display: block;
} */
.con_proc_btn{
	display: flex;

}


@media only screen and (max-width: 768px) {
	.shopping__cart__table table {
	  width: 100%;
	  display: block;
	  overflow-x: auto;
	}
    .cart__total_form {
        margin-top: 20px;
    }
  
	.shopping__cart__table table thead,
	.shopping__cart__table table tbody {
	  display: block;
	}
  
	.shopping__cart__table table tbody tr {
	  display: inline-block;
	  width: 100%;
	}
  
	.shopping__cart__table table tbody tr td {
	  display: block;
	  width: auto;
	  text-align: left;
	}
  
	.cart_table_title_tr {
	  display: table-row;
	}
	.continue__btn_proceed {
		font-size: 13px;
		font-weight: 700;
		letter-spacing: 1px;
		width: 150px;
		height: 47px;
	}
	.continue__btn_a {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        width: 187px;
        height: 47px;
    }
	/* mobileview table start*/
	.fill_view{
		display: none !important;
	}
.mob_cart_main{
	display: block;
	margin: 8%;
	margin-top: 0px;
}
.mob_cart1{
	display: flex;
	flex-direction: column;
    gap: 13px;
	border-bottom: 1px solid #8080802e;
	padding: 22px 0px 10px 0px
}
.mob_cartsec1{
	display: flex;
}
.imgdiv {
	width: 36%;
	text-align: start;
}

.imgdiv > img{
		width: 100px;
		height: 100px;
        object-fit: contain;
}
.in_cart2 {
        margin-bottom: 4px;
        width: 27px;
        margin: 0px;
        margin-bottom: 20px;
    }
    .quant {
        /* margin-top: 38px; */
        display: flex;
        gap: 20px;
        font-size: 17px;
        font-weight: 600;
        width: 84px;
        margin: auto;
    }
.quant_amt {
	padding-top: 11px;
	width: 47%;
	text-align: center;
}

.remdiv {
	width: 17%;
	text-align: end;
}
.removeicon2 {
    background-color: #f3f2ee;
    width: 40px;
    height: 40px;
    border-radius: 24px;
    padding: 6px;
    margin-top: 31px;
}
.mob_cartsec2{
	text-align: start;
}
.mob_cartsec2>h6{
	font-size: 16px;
	word-break: break-all;

}
.mob_cartsec2>h5{
	font-size: 13px;
}
.amt{
	font-size: 16px;
	font-weight: 500;
}
/* mobileview table end*/


  }
