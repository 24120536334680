.conatctus_page{
    /* margin-top: 96px; */
    /* background-color: #f5f3f382; */
}
.contact-banner {
  height: 393px;
  background: #d1d1d1;
  text-align: center;
  padding-top: 150px;
  color: white;
  background-size: 100% 100%;
    background-image: url("../../Assets/images/contactbanner.jpeg");
  }
  
  .contact-banner h1 {
    margin: 0;
  }.aboutus_mian {
    position: relative;

}
.about_banner{
    height: 393px;
    background: #e5e4e4;
    /* padding-top: 46px; */
    /* position: relative; */
    position: sticky;
    top: 0px;
    z-index: -1;

}
.about_banner_content{
    position: absolute;
    top: -1px;
    color: white;
    width: 100%;
    height: 394px;
    padding: 152px;
    background: #00000040;
}
.about_banner_content >h1{
    margin-bottom: 0px;
}
.about_banner_content >p{

}
.about_banner >img{
    height: 393px;
    width: 100%;
    object-fit: cover;
}
  
.contact-main {
  width: 100%;
  margin: 0 auto;
  padding: 62px 81px;
  background-color: #f5f3f3;
}
  
  .contact-method {
    display: flex;
    justify-content: center; /* Center the contact methods horizontally */
    margin-bottom: 40px;
    padding: 0px 4%
  }
  
  .method-item {
    display: flex;
    align-items: center;
    margin: 0 20px; 
  }
  
  .contact-icon {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    background: #A90000;
    padding: 9px;
    border-radius: 8px;
    color: white;
    min-width: 40px;
}
  
  .method-item span {
    font-size: 1rem;
    min-width: 90px;
  }
  
  .contact-form-address {
    display: flex;
    justify-content: space-between;
    padding: 11px 18%;
}
  
  .contact-form {
    /* flex: 1; */
    margin-right: 20px;
    width: 60%;
  }
  
  .form {
    /* background: white; */
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  .row1{
    display: flex;
    gap: 20px;
  }
  .rowcol{
    width: 48%;
    text-align: start;
  }
  .form-group {
    margin-bottom: 15px;
    text-align: start;

  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    margin-top: 3px;
    background: transparent;
  }

  
  .form-control:focus {
    box-shadow: none;
    background: transparent;
    border-color: black;
  }
  
  .btn_msg {
    background-color: #000000;
    color: white;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    width: 230px;
    border: 3px solid black;

  }
  
  .btn_msg:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    transition: all, 0.5s;
    border: 3px solid black;
  }
  .btn_msg:focus {
    background-color: #000000;
    color: white;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    width: 230px;
    border: 3px solid black;
  }
  
  .contact-address {
    width: 40%;
    padding-top: 22px;
    text-align: start;
}
.contact-address > h6{
  margin-top: 29px;
    font-size: 17px;
}
  .rowcol > label{
    font-size: 16px;
    font-weight: 500;
  }
  .address-item {
    margin-bottom: 20px;
    height: auto;
    /* background: #ffffff; */
    border-radius: 6px;
    text-align: justify;
    /* border: 1px solid #e3e3e3b9; */
    padding: 14px 20px 1px 20px;
    
}
.address-item:hover {
    box-shadow: 6px 3px 21px 0px #9794944d;
    transition: all, 0.4s;


    

}
.address-item_selected{
  box-shadow: 6px 3px 9px 4px #9794944d;
  transition: all, 0.4s;
}
  .form-group> label{
    font-size: 16px;
    font-weight: 500;
  }

  /* glow text */
  .glow_text {
    font-size: 11px;
    background: black;
    color: white;
    padding: 2px 5px;
    border-radius: 5px;
    bottom: 1px;
    /* left: 155px; */
    margin-left: 4px;
}
.map{
  padding-top: 50px;
}

@media (max-width: 991px){
  .contact-method {
    display: flex;
    flex-direction: column;
  }
  .contact-method {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    padding: 0px 0%;
    gap: 11px;
}
  .method-item {
    display: flex;
    align-items: center;
    margin: 0 0px;
  }
  .contact-iconadd{
    width: 42px;
  }
  .contact-form-address {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 11px 0%; 
  }
  .contact-form {
    /* flex: 1; */
    margin-right: 20px;
    width: 100%;
  }
  .form {
    /* background: white; */
    padding: 0px;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  .btn_msg {
    width: 100%;
  }
  .contact-address {
    width: 100%;
  }
  iframe {
    height: 300px;
  }
  .method-item span {
    font-size: 1rem;
    font-size: 15px;
    text-align: start;
  }
  .contact-main {
    padding: 62px 14px;
}
.about_banner_content {
  padding: 166px 57px;
}
  

  

}