.LoginSignup {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: auto;
  gap: 20px;
  margin: auto;
  background: #d7d7d7;
  border-radius: 11px;
  padding-bottom: 34px;
  margin-top: 142px;
  margin-bottom: 100px;
}

.LoginSignup_button {
  background: #000000;
  color: white;
  height: 47px;
  width: 80%;
  border: none;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 9px;
  margin-top: 0px;
}
.LoginSignup > input {
  height: 47px;
  width: 100%;
}

.cke {
  display: flex;
  margin-top: 6px;
  width: 100%;
  margin-left: 0%;
}
.check {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-right: 1px;
}
.cke > p {
  text-align: start;
  margin-left: 10px;
  line-height: 19px;
  font-size: 15px;
  width: 77%;
}

.forgotp {
  width: 100%;
  text-align: end;
  font-size: 15px;
  margin-top: 8px;
}

.already {
  margin-top: 11px;
  width: 100%;
  text-align: end;
  font-size: 15px;
}

.logreg {
  text-decoration: underline;
  color: #ed6b6b;
}

.image-container {
  position: relative;
}

.loginbg {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.state-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 30px;
  font-weight: bold;
}
.label_filed {
  display: inline-block;
  width: 12%;
  margin-right: 13px;
  text-align: end;
}
.inputlogin{
  background: none;
  border: none;
  height: 44px;
  outline: none; 
  border-bottom: 1px solid #b3b0b0;
  width: 57%;
  font-size: 15px;
}
.inputlogin:hover{
  border-bottom: 1px solid #000000;
}
.inputlogin:focus{
  border-bottom: 1px solid #000000;

}

.btn_div {
  width: 71.5%;
  text-align: end;
  margin: auto;
}
.log_link{
  text-decoration: underline;
  color: rgb(189, 84, 84);
}
@media (max-width: 786px){
  .LoginSignup {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh; /* Set height to 100% of the viewport height */
    gap: 20px;
    margin-top: 0px;
  }
  .label_filed {
    margin-right: 38px;
  }
  .btn_div {
    width: 100.5%;
    text-align: center;
    /* margin: auto; */
    margin-top: 0px;
  }
  .cke > p {
    padding-right: 42px;
  }
}


