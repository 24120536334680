.admin_main_add{
    display: flex;
}
.addproduct_main{
    text-align: center;
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.addproduct_main> h3 {
    margin-bottom: 38px;
}
.addproduct_form > label, input, select, button {
    width: 41%;
    margin: auto;
    margin-bottom: 17px;
    margin-top: 6px;
}
.add_form > label {
    display: inline-block;
    width: 30%;
    margin: auto;
    text-align: start;
    padding-left: 20%;
}
.add_btn{
    background: black;
    color: white;
    height: 41px;
    border: none;
}

@media (max-width: 786px) {
    .add_form{
        display: flex;
        flex-direction: column;
     }
     .admin_main_add {
        display: flex;
        flex-direction: column;
    }
    .addproduct_main {
        text-align: center;
        width: 100%;
    
    }
    .add_form > label {
        width: 74%;
        padding-left: 0%;
    }
    .addproduct_form > label, input, select, button {
        width: 74%;
    }
    .addproduct_main > h3{
        margin: 30px 0px;
    }
}
