.admin_main_list {
    display: flex;
    position: relative;
}

.listproduct_main {
    text-align: center;
    width: 80%;
}

.listproduct_main > h3 {
    margin-bottom: 30px;
    margin-top: 30px;
}

.table-responsive {
    overflow-x: auto;
}

.list_img {
    width: 100px;
    height: 100px;
}

.list_td > td {
    max-width: 195px;
    min-width: 125px;
    word-break: break-all;
    padding: 1.25rem 0rem;
}

/* edit product */
.editproduct_main {
    width: 47%;
    padding-top: 27px;
    height: auto;
    position: fixed;
    background-color: rgb(65 65 65 / 88%);
    left: 29%;
    top: 184px;
    padding-bottom: 20px;
}
.editproduct_main > h3{
    color: white;
}
.editproduct_main > label {
    display: inline-block;
    width: 119px;
    color: white;
}
.editproduct_main > input{
    min-width: 200px;
    height: 45px;
}
.sel_drop_in{
    min-width: 200px;
    height: 45px;
}
.imgedit {
    display: flex;
    width: 70%;
    margin-left: 19%;
}
.imgedit > label {
    color: white;
}
.imgedit > input {
    width: 33%;
    margin: 0px;
    margin-left: 34px;
    margin-right: 10px;
    color: white;
    font-size: 14px;

}
.mod_main {
    transform: none;
    top: 100px;
}
.up_edit {
    width: 160px;
    margin: 5px;
    margin-top: 20px;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    height: 45px;
    border: none;
}
.cam_edit {
    width: 160px;
    margin: 5px;
    margin-top: 20px;
    background: black;
    color: white;
    height: 45px;
    border: none;
}

@media (max-width: 786px) {
    .admin_main_list {
        display: flex;
        position: relative;
        flex-direction: column;
    }
    
    .listproduct_main {
        text-align: center;
        width: 100%;
    }
    
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
    }
    .table_list{
        margin: 20px;
    }
    .editproduct_main {
        width: 47%;
        min-width: 356px;
        height: auto;
        left: 14%;
        top: 184px;
    }
    .editproduct_main > input {
        min-width: 200px;
        height: 38px;
        margin-bottom: 10px;
    }
    .sel_drop_in {
        min-width: 200px;
        height: 38px;
        margin-bottom: 10px;

    }
    .up_edit {
        width: 150px;
        margin: 5px;
        margin-top: 20px;

    }
    .cam_edit {
        width: 150px;
        margin: 5px;
        margin-top: 20px;

    }
    .editproduct_main {
        padding-bottom: 20px;
    }

}
