.item {
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(17.33% - 10px);
  margin: auto;
  text-align: start;
  min-height: 265px;
  box-shadow: 1px 2px 8px 8px #f9f9f9bf;
  

}
.item:hover {
  /* background: #fbfbfb;
  row-gap: 20px; */
  box-shadow: none;
  /* box-shadow: 1px 2px 8px 8px #f9f9f9bf; */

}
.product__item {
  overflow: hidden;
  margin-bottom: 5px;
}
.product__item.sale .product__item__pic .label {
  color: #ffffff;
  background: #111111;
}
.product__item.sale .product__item__text .rating i {
  color: #f7941d;
}
.product__item:hover .product__item__pic .product__hover {
  right: 20px;
  opacity: 1;
}
.product__item .anumatn {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  transition: bottom 0.8s ease, opacity 0.3s ease;
  opacity: 0;
  width: 67%;
  background: black;
  color: white;
  height: 38px;
  border: none;
  border-radius: 6px;
  z-index: 1000;
}

.product__item:hover .anumatn {
  /* bottom: 9px; */
  bottom: -12px;
  opacity: 1;
  z-index: 1000;
}
.product__item__pic {
  /* height: 260px;
  position: relative;
  background-position: center center;
  background-size: 100% 100%;    
  background-color: rgb(239 239 239); */
  height: 137px;
    position: relative;
    background-position: center center;
    background-size: 100% 100%;
    background-color: rgb(255 255 255);
    padding: 4px 38px;
}
.product__item__text {
  padding-top: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.product__item__text h6 {
  color: #111111;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  transition: all 0.3s;
}
.product__item__text h5 {
  color: #0d0d0d;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
}
.prize {
  font-weight: 700 !important;
  font-size: 16px !important;
}
.product__item__text .product__color__select {
  position: absolute;
  right: 0;
  bottom: 0;
}
.product__color__select_p {
  margin-top: 0;
  margin-bottom: 0rem;
  color: #970513;
  font-size: 13px;
  font-weight: 600;
  text-align: end;
}
.items {
  flex: 0 0 auto;
  width: 90.333333%;
  padding: 5%;
  /* min-width: 318px; */
  z-index: 10;
  position: relative;
  min-width: 257px;
}
.p_imgurl{
  width: 100%;
  height: 100%;
  transition: transform 0.9s ease;
  object-fit: contain;
}
.p_imgurl:hover{

  transform: scale(1.4);
  
}

@media (max-width: 786px){
  .items {
    width: 68.333333%;
    min-width: 179px;
  }
  .item {
      max-width: 190px;
      height: 255px;
      min-height: 259px;
  }
    
  .product__item__pic {
    height: 116px;
    padding: 4px 8px;
  }
  .product__item .anumatn {
    width: 80%;
    font-size: 14px;
    height: 30px;

  }
  .product__item__text {
    padding-right: 12px;
  }
  .product__item:hover .anumatn {
    bottom: -10px;
  }
  .product__item .anumatn {
    bottom: -25px;
    font-size: 12px;

  }
}

