/* Ensure the navbar is fixed at the top */
.navbarr {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 97px;
    padding: 0px 30px;
    background-color: rgb(0, 0, 0);
    z-index: 1000;
}

.navbar-custom {
    padding: 0px 40px;
    padding-right: 149px;
    padding-top: 11px;
    position: sticky;
    top: 0px;
    z-index: 2000;
    background-color: black !important;
}

.navbar-brand, .nav-link {
    font-size: 1.2em;
}

.logonav {
    width: 90px;
}

.navelementtitle {
    font-size: 17px;
    font-weight: 600;
    color: #c5c4c4;    
    padding: 10px 0px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    position: relative;
    transition: color 0.3s ease;
}

.navelementtitle:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #A90000;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.navelementtitle:hover {
    color: white;
}

.navelementtitle:hover:after {
    width: 100%;
    left: 0;
}

.navelementtitle.active {
    color: white;
}

.navelementtitle.active:after {
    width: 100%;
    left: 0;
}

.navc {
    flex-direction: row;
    gap: 42px;
}

.nav-dropdown-title {
    font-size: 18px;
    font-weight: 600;
    color: #c5c4c4;
    padding: 14px 0px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    position: relative;
    transition: color 0.3s ease;
}

.nav-dropdown-title:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #A90000;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.nav-dropdown-title:hover {
    color: white;
}

.nav-dropdown-title:hover:after {
    width: 100%;
    left: 0;
}

.nav-dropdown-title.active {
    color: white;
}

.nav-dropdown-title.active:after {
    width: 100%;
    left: 0;
}

.nav-center-content {
    gap: 30px;
}

.cartdiv {
    position: absolute;
    right: 7%;
}
/* .cart- icon  */
/* .cart {
    color: white;
    width: 24px;
    height: 24px;
} */
 /* .cart - button */
.cart {
    color: white;
    width: 143px;
    height: 47px;
    padding: 8px 21px;
    background: #A90000;
    border: none;
    border-radius: 7px;
    line-height: 17px;
    font-weight: 400;
    font-size: 15px;
}
/* .cart- icon  */
/* .count {
    color: white;
    position: absolute;
    bottom: 18px;
    left: 25px;
} */
 /* .cart - button */
.count {
    color: white;
    position: absolute;
    bottom: 49px;
    left: 129px;
    background: #00a300;
    border-radius: 21px;
    width: 25px;
    height: 25px;
}

.loginbutton {
    width: 100px;
    border: 2px solid #A90000;
    border-radius: 48px;
    height: 43px;
    color: white;
    font-size: 16px;
    background-color: transparent;
}

/* Dropdown menu animations */
@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUp {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-10px);
    }
}

.category-dropdown {
    display: flex !important;
    flex-direction: column;
}

.navbar-nav .dropdown-menu {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.33s, opacity 0.33s ease, transform 0.33s ease;
    transform: translateY(-10px);
    height: 0;
    overflow: hidden;
}

.navbar-nav .show > .dropdown-menu {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0s;
    animation: slideDown 0.33s ease forwards;
    background: black;
    --bs-dropdown-min-width: 15rem;
    box-shadow: 0px -2px 6px 0px white;
    padding: 0px;
    height: auto;
    /* max-height: 300px; 
    overflow-y: auto;  */
}

.navbar-nav .dropdown-item {
    color: white;
    text-align: center;
    font-size: 15px;
    height: 39px;
    padding-top: 10px;
}

/* item hover */
.navbar-nav .dropdown-item:hover {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 15px;
    border-radius: 4px;
    background-color: rgb(63, 62, 62);
}

/* item active */
/* Override the default Bootstrap active dropdown item background color */
.navbar-nav .dropdown-item.active,
.navbar-nav .dropdown-item:active {
    background-color: white !important;
    color: black !important;
    border-radius: 4px;
}
@media (max-width: 1240px) {
    .navc {
        gap: 14px;
    }
    .navelementtitle {
        font-size: 13px;
    }
    .nav-dropdown-title {
        font-size: 13px;
        top: 2px;
    }
    .navbar-brand, .nav-link {
        font-size: 13PX;
    }
    
}
/* Media queries */
@media (max-width: 991px) {
    .navbar-custom {
        padding: 0px 0px;
        padding-top: 11px;
        position: sticky;
        top: 0px;
        z-index: 2000;
    }

    .toggler_navv {
        width: 54px;
        margin-right: 23px;
        margin-top: 0px;
    }

    .cartdiv {
        position: absolute;
        right: 76px;
        top: 21px;
    }

    .loginbutton {
        width: 100%;
        border: none;
        background: transparent;
        height: 66px;
        font-size: 17px;
        font-weight: 600;
        color: #c5c4c4;
        padding: 6px 4px;
        text-transform: uppercase;
        margin-top: 0px;
    }

    .nav_center_contend {
        gap: 4px;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .navelementtitle:after {
        background: none;
    }

    .nav-dropdown-title:after {
        background: none;
    }

    .navbar-nav .dropdown-menu {
        position: static;
        background: transparent;
    }

    .dropdown-item {
        color: white;
        text-align: center;
        font-size: 15px;
        border-radius: 4px
    }

    .dropdown-item:hover {
        color: rgb(158, 6, 6);
        text-align: center;
        font-size: 15px;
    }
    /* mob */
    .navbar-nav .show > .dropdown-menu {
        box-shadow: none;
    }

    .navbar-nav .dropdown-item {
        color: #a9a7a7;
        text-align: center;
        font-size: 14px;
        height: 39px;
        padding-top: 0px;
    }

    .navbar-nav .dropdown-item:hover {
        color: rgb(255, 255, 255);
        text-align: center;
        font-size: 15px;
        border-radius: 4px;
        background-color: rgb(0, 0, 0);
    }

    .navbar-nav .dropdown-item.active,
    .navbar-nav .dropdown-item:active {
        background-color: rgb(0, 0, 0) !important;
        color: rgb(133, 0, 0) !important;
        border-radius: 4px;
        font-size: 17px;
        font-weight: 700;
    }

    .navbar-collapse {
        overflow-y: scroll;
        min-height: 740px;
    }

    /* Dropdown menu height and scrolling for mobile */
    .navbar-nav .show > .dropdown-menu {
        overflow-y: auto; /* Enable vertical scrolling */
        max-height: 54vh;
    }
    .cart {
        width: 88px;
        height: 42px;
        padding: 5px 5px;
        font-size: 13px;
        line-height: 16px;
    }
    .count {
        bottom: 49px;
        left: 77px;
        width: 21px;
        height: 20px;
        font-size: 13px;
    }
}
