.Whatwedo_main {
    background-color: #830202;
    margin-top: -7px;
    position: relative;
    /* height: 481px; */
    /* margin-bottom: 149px; */
}
.whatwedi_sub {
    PADDING: 85PX 4%;
    max-width: 1050px;
    margin: auto;
}

.whatwedo_cards {
    display: flex;
    text-align: -webkit-center;
    /* width: 100%; */
    gap: 4%;
}
/* new waht we */
.whatwedo_cards{
    display: flex;
    flex-direction: column;
}
.whatwedo_cards {
    gap: 20px;
    margin-top: 30px;
}
.whatwedo_cards_new1{
    display: flex;
    gap: 20px;

}
.new_l {
    background: white;
    border-radius: 16px;
    text-align: left;
    padding: 21px 24px 10px 29px;
    position: relative;
}
.new_l >h5{
    margin-left: 18px;
}
.headno {
    color: black;
    -webkit-text-fill-color: #ffffff00;
    -webkit-text-stroke: 1px #a1989847;
    font-size: 51px;
    position: absolute;
    top: 1px;
    left: 14px;
}
@media(max-width: 786px){
    .Whatwedo_main {
        margin-top: -7px;
        position: relative;
        height: auto;
        margin-bottom: 0px;
        /* padding-bottom: 0px; */
    }
    .whatwedi_sub{
        PADDING: 70PX 4%;
    }
    .whatwedo_cards {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        row-gap: 20px;
        margin-top: 35px;
    }
    .whatwedo_cards {
        gap: 15px;
    }
    .whatwedo_cards_new1{
        gap: 15px;
        flex-direction: column;

    
    }
    .whatwedo_cards_new1{
    }
    
    .new_l {
        min-height: 140px;
    }
    .new_l>h5 {
        font-size: 16px;
    }
    .new_l>p {
        font-size: 15px;
    }
    .headno {
        font-size: 43px;
    }
}

