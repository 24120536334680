.aboutus_mian {
    position: relative;

}
.about_banner{
    height: 393px;
    background: #e5e4e4;
    /* padding-top: 46px; */
    /* position: relative; */
    position: sticky;
    top: 109px;
    z-index: -1;

}
.about_banner_contend{
    position: absolute;
    top: -1px;
    color: white;
    width: 100%;
    height: 394px;
    padding: 152px;
    background: #00000040;
}
.about_banner_contend >h1{
    margin-bottom: 0px;
}
.about_banner_contend >p{

}
.about_banner >img{
    height: 393px;
    width: 100%;
    object-fit: cover;
}
/* section two about company */
.about_company {
    position: relative;
    width: 100%;
    z-index: 10;
    background: white;
}
.abo {
    display: flex;
    padding: 101px 4%;
    padding-top: 40px;
    max-width: 1300px;
    margin: auto;
}
.about_company_left {
    width: 55%;
    background: white;
    text-align: start;
    padding-top: 57px;
    padding-right: 0%;
}

.about_company_left>h1 {

}
.unline {
    width: 100px;
    border: 1px solid #bb0505;
    height: 2px;
     margin-bottom: 25px;
}
.about_company_left > p {
    width: 92%;
}

.about_company_right {
    width: 45%;
    background: white;
    text-align: end;
    margin-right: 54px;
    position: relative;
    margin-top: 118px;
}
.about_company_right > img {
    width: 300px;
    height: 462px;
    object-fit: cover;
    margin: auto;
    
}
.about_company_right>h2 {
    color: black;
    -webkit-text-fill-color: #ffffff00;
    -webkit-text-stroke: 2px #d9d7d769;
    font-size: 102px;
    position: absolute;
    position: absolute;
    top: -64px;
    right: -50px;
    display: none;
}
/* section two about company */
@media(max-width: 786px){
    .about_banner_contend {
        padding: 145px 70px;
    }
    
    /* sec2 */
    .abo {
        flex-direction: column;
        display: flex;
        padding: 26px 14px;
    }
    .about_company_right {
        width: 100%;
        background: white;
        text-align: center;
        /* margin-right: 54px; */
        position: relative;
        margin-top: 63px;    
    }
    .about_company_left {
        width: 100%;
        background: white;
        text-align: center;
        padding-top: 26px;
        padding-right: 0%;
    }
    .about_company_right > img {
        width: 300px;
        height: 309px;
        object-fit: cover;
        margin: auto;
    }
    .about_company_right>h2 {
        position: absolute;
        top: -64px;
        right: -17px;
    }
    .about_company_left > p {
        width: 100%;
    }
    .unline{
        margin: auto;
        margin-bottom: 30px;
    }
    .about_banner {
        top: 104px;
    }
    
    
}