.loadall {
    margin-top: 35px;
    margin-bottom: 50px;
}

.loadall > button {
    width: 187px;
    height: 40px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    transition: all, 0.5s;
    border: 1px solid black;
    /* border-radius: 6px; */

}
.loadall > button:hover{
    
    border: rgb(0, 0, 0);
    background-color: rgb(0, 0, 0);
    /* border-radius: 6px; */
    color: rgb(255, 255, 255);
    border: 1px solid black;

}
.all {
    margin-top: 15px;
    margin-bottom: 59px;
}
.no_item{
    margin: auto;
    height: 250px;

}