.banner {
    background-color: #f3f2ee;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    color: black;
    
}
.ShopCategory_2{
    margin-bottom: 60px;
}
.banner_cat_in {
    max-width: 1400px;
    /* margin: auto; */
    width: 100%;
    margin: auto;
    padding: 0px 4%;
}
.all_spinner{
    margin: auto;
}
.banner > h1{
    text-transform: uppercase;
}
.banner > img{

}

.cat_products {
    display: flex;
    flex-wrap: wrap;
    /* margin-left: 12%; */
    padding: 0% 4%;
    margin-top: 10px;
    column-gap: 20px;
    row-gap: 34px;
    margin-bottom: 0px;
    min-height: 27vh;
}
.load_more {
    margin: auto;
    /* margin-bottom: 50px; */
    margin-top: 20px;
    width: 100%;
}
.loader_sp2{
    width: 100%;
    text-align: center;
    height: 100px;
}

.load_more > button {
    width: 187px;
    height: 40px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    transition: all, 0.5s;
    border: 1px solid black;
    /* border-radius: 6px; */

}
.load_more > button:hover{
    
    border: rgb(0, 0, 0);
    background-color: rgb(0, 0, 0);
    /* border-radius: 6px; */
    color: rgb(255, 255, 255);
    border: 1px solid black;

}
.sort{
    width: 100px;
    height: 30px;
    border: 1px solid grey;
    margin: auto;
    background-color: transparent;
    border-radius: 30px;
    padding-top: 2px;
    margin-bottom: 20px;
}
/* search */
.search_div_cat{
    height: 100px;
    margin-top: 50px;
}
.formdiv_cat {
    width: 30%;
    margin: auto;
    position: relative;
    /* top: 519px;
    left: 36%; */
}

.ser_ic{
    position: relative;
    left: 29px;
    top: 32px;
    color: #A90000;
}

.search_in_cat {
    width: 100% !important;
    padding: 10px !important;
    padding-left: 36px !important;
    border: 1px solid #c5c5c5 !important;
    border-radius: 32px !important;
    margin-top: 16px !important;
    background: #ffffffc4 !important;
}

.no_t_cat{
    margin: auto;
    height: 250px;
}



.enquiry-btn {
    background-color: #c7c7c7;
    color: #000000;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 310px;
    min-height: 54px;
    transition: background-color 0.3s ease;
}
  
  .enquiry-btn:hover {
    background-color: #e0e0e0;
    font-weight: 500;
  }
  .coming-soon-box {
    /* border: 2px solid #ff0000; */
    width: 100%;
    box-shadow: 0px 0px 6px -3px grey;
    padding: 69px;
}
  
  .coming-soon-text {
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
@media(max-width: 869px){
    .cat_products {
        padding: 0px 14px;
        column-gap: 0px;
    }
    .formdiv_cat {
        width: 80%;
    }
    .enquiry-btn {
        width: 192px;
    }
        
}