.banner_h_29 {
    align-items: center;
    justify-content: center;
    background-image: url('../../Assets/images/homebanner4.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 90vh;
    position: relative;
}

.banner-content_h_29 {
    display: flex;
    width: 100%;
    color: rgb(0, 0, 0);
    height: 90vh;
}

.banner-left_h_29 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: left;
    padding: 0px 60px 0px 83px;
    background-color: rgb(255 255 255 / 93%);
}

.banner-left_h_29 > h1 {
    font-size: 52px;
    margin-bottom: 20px;
}

.banner-left_h_29 > p {
    width: 85%;
}

.banner-middle_h_29 {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #891a01eb;
    position: relative;
}

.banner-middle_h_29 img {
    height: auto;
    width: 815px;
    position: absolute;
    top: 33ch;
}

.banner-right_h_29 {
    background-color: #fefefeed;
    width: 25%;
}

.search_new_29 {
    display: flex;
    align-items: center;
    width: 36%;
    margin: auto;
    min-width: 600px;
    margin-top: 30px;
}

.search_new_29 > input {
    height: 49px;
    border: 2px solid #990202;
    padding-left: 20px;
    flex: 1;
}

.in_mser:focus {
    border: 2px solid #990202;
    padding-left: 20px;
}

.search_new_29 > button {
    height: 49px;
    width: 128px;
    background: #890000;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
}

.ser_new {
    margin-left: 7px;
}

@media (max-width: 1300px) {
    .banner-middle_h_29 img {
        height: auto;
        width: 580px;
        position: absolute;
        top: 33ch;
    }
    .banner-left_h_29 {
        width: 55%;
    }
    .banner-middle_h_29 {
        width: 33%;
    }
    .banner-right_h_29 {
        background-color: #fefefeed;
        width: 15%;
    }
}

@media (max-width: 1000px) {
    .banner-middle_h_29 img {
        height: auto;
        width: 457px;
        right: 95px;
        position: absolute;
        top: 38ch;
    }
    .banner-left_h_29 {
        width: 50%;
    }
    .banner-middle_h_29 {
        width: 50%;
    }
    .banner-right_h_29 {
        width: 0%;
        display: none;
    }
    .banner-left_h_29 > h1 {
        font-size: 33px;
    }
    .banner-content_h_29 {
        height: 715px;
    }
    .banner_h_29 {
        height: 715px;
    }
}

@media (max-width: 768px) {
    .banner-content_h_29 {
        flex-direction: column;
        height: 700px;
    }
    .banner-left_h_29, .banner-middle_h_29, .banner-right_h_29 {
        width: 100%;
    }
    .banner-middle_h_29 img {
        width: 50%;
    }
    .search_new_29 {
        min-width: 240px;
        margin-top: 42px;
        /* padding: 0px 26px; */
        width: 67%;
    }
    .search_new_29 > input {
        height: 43px;
        width: 52%;
        border-radius: 0px;
    }
    .search_new_29 > button {
        width: 88px;
        height: 43px;
        font-size: 13px;
    }
    .banner-left_h_29, .banner-middle_h_29, .banner-right_h_29 {
        padding: 0px 16px 0px 24px;
    }
    .banner-left_h_29 {
        height: 480px;
    }
    .banner-middle_h_29 {
        height: 222px;
    }
    .banner-middle_h_29 img {
        width: 379px;
        right: 0px;
        top: -11ch;
    }
    .banner_h_29 {
        height: 700px;
    }
}
