.up_in{
    width: 20%;
    margin: auto;
    margin-bottom: 17px;
    margin-top: 6px;
}
.up_bt{
    background-color: black;
    color: white;
    border: none;
}