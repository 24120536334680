.elcome_main {
    padding: 58px 60px 7px 83px;
    text-align: start;
    WIDTH: 65%;
    MARGIN: auto;
}
.elcome_main > h1 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 30px;
}
.elcome_main > h2{
    font-size: 21px;
}
.elcome_main > p{
    
}
.ul_list{

}
.ul_list >li{

}
.cate1_h {
    width: 186px;
    height: 123px;
    /* border: 1px solid black; */
    text-align: center;
    padding: 9px;
    box-shadow: 5px 2px 10px -2px grey;
    margin-top: 6px;
}
.cate1_h > img{
    width: 57px;
    height: 53px;
    margin: 7px;
}
.cate1_h > h3{
    font-size: 14px;
    font-weight: 600;
}
.category_home{
    display: flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: clip;
    gap: 20px;
    height: 160px;
    margin-bottom: 23px;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 transparent; /* For Firefox */
}
@media(max-width: 786px){
    .elcome_main {
        padding: 58px 30px 7px 30px;
        width: 100%;
    }
    .cate1_h {
        width: 111px;
        height: 121px;
        padding: 5px;
    }
    .cate1_h > h3 {
        font-size: 12px;
    }
}
