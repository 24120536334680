.sidebar{
    width: 20%;
    height: 840px;
    background-color: rgb(236, 236, 223);
    position: sticky;
    top: 109px;
    right: 0px;
}
.item_h6{
    height: 90px;
    padding-top: 50px;
}
.item_h6_2{
    margin-bottom: 20px;
}
@media (max-width: 786px){
    .sidebar {
        width: 100%;
        height: auto; 
    }
    .item_side {
        width: 82%;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(17.33% - 10px);
        margin: auto;
        text-align: start;
        display: flex;
        gap: 30px;
    }
    .item_h6 {
        height: 73px;
        width: 50%;
        padding-top: 32px;
        text-align: center;
    }
    .item_h6_2{
        height: 73px;
        width: 50%;
        padding-top: 32px;
        text-align: center;
        
    }
}