.ProductDisplay{
    display: flex;
    width: 100%;
    margin-top: 50px;
}
.ProductDisplay_left{
    width: 45%;
    text-align: center;

}
.ProductDisplay_left > img{
    width: 50%;
}

.ProductDisplay_right{
    width: 55%;
    padding: 51px 82px 0px 1px;
}

.ProductDisplay_right > button{
    width: 200px;
    height: 40px;
    background-color: rgb(236, 61, 61);
    color: white;
}