.foot{
    width: 100%;
    background-color: black;
    color: white;
    height: 190px;
    padding: 32px 6% 42px 6%;
    margin-top: 0px;
}
.foot > p {
    font-size: 13px;
    color: #757575;
    margin-top: 13px;
    border-top: 1px solid #80808063;
    padding-top: 14px;
}
.footer_main {
    display: flex;
    
}
.footer_left{
    width: 50%;
}
.footer_left{
    display: flex;
    flex-direction: column;
    gap: 42px;
}
.f_logo{
    display: flex;
}
.f_logo> img{
    width: 100px;
    height: 100px;
}
.f_logo> h1 {
    font-size: 18px;
    text-align: justify;
    margin-top: 22px;
    font-weight: 700;
}
.f_con {
    display: flex;
    gap: 18px;
}
.f_con_icon1 {
    color: #A90000;
    width: 22px;
    height: 22px;
}
.f_con_icon2 {
    color: #A90000;
    width: 25px;
    height: 18px;
}
.f_con_icon3 {
    color: #A90000;
    width: 17px;
    height: 16px;
}
.mail {
    display: flex;
    gap: 5px;
    border-bottom: 1px solid #000000;

}
.mail:hover {
    border-bottom: 1px solid #610202;
}
.mail > p {
    font-size: 13px;
    margin-bottom: 6px;
    min-width: 66px;
}




.footer_right{
    width: 50%;
    display: flex;
    /* flex-direction: column; */
    justify-content: end;
    align-items: center;
}
.footer_right>h3 {
    font-size: 15px;
    width: 47%;
    margin: auto;
    text-align: start;
    margin-bottom: 0px;
}
.contents_f{
    width: 57%;
    margin: auto;
    text-align: start;
    font-size: 13px;
    height: 30px !important;
}
.footer_nav{
    width: 40%;
    margin: auto;
    display:flex ;
}

@media(max-width: 786px){
    .foot {
        height: auto;
        padding-bottom: 1px;

    }
    .footer_main {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: black;
        color: white;
        height: auto;
        padding: 0px 1% 0% 1%;
    }
    .footer_left {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 21px;
    }
    .mail > p {
        font-size: 12px;
    }
    .f_logo {
        display: flex;
        margin: auto;
    }
    .f_con {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: auto;
    }.mail {
        display: flex;
        gap: 3px;
        margin: auto;
    }
    .footer_right {
        width: 100%;
        margin-top: 20px;
    }
    .footer_right>h3 {
        font-size: 17px;
        width: 94%;
        margin: auto;
        text-align: center;
        margin-bottom: 15px;
    }
    .contents_f {
        width: 57%;
        margin: auto;
        text-align: center;
        height: 29px;
    }
    .footer_nav {
        width: 89%;
    }
    .f_logo> img {
        width: 78px;
        height: 78px;
    }
    .f_logo> h1 {
        font-size: 15px;
        text-align: justify;
        margin-top: 16px;
        font-weight: 700;
    }
}